import HeartIcon from '@mui/icons-material/Favorite';
import RemoveIcon from '@mui/icons-material/Remove';
import WarningIcon from '@mui/icons-material/WarningAmber';
import {
  Box,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import useLongPress from '../../hooks/useLongPress';
import { Animation, Player } from './PlayerCircle';

const styles = {
  player: {
    alignItems: 'center',
    WebkitTapHighlightColor: 'transparent',
  },
  head: {
    height: '2rem',
    width: '2rem',
    border: '1px solid black',
    borderRadius: '2rem',
    backgroundColor: '#fff',
  },
  body: {
    height: '2.5rem',
    width: '4rem',
    border: '1px solid black',
    borderRadius: '2.5rem 2.5rem 0 0',
    backgroundColor: '#fff',
    justifyContent: 'center',
    alignItems: 'center',

    '& p': {
      marginTop: '0.2rem',
    },
  },
  floatingHeart: {
    position: 'absolute',
    bottom: '5rem',
    left: '-1.35rem',
    fontSize: '20px',
    whiteSpace: 'nowrap',
    animation: 'floatUp 2.5s forwards',

    '@keyframes floatUp': {
      '0%': {
        opacity: 1,
      },
      '50%': {
        opacity: 0,
      },
      '100%': {
        transform: 'translateY(-5rem)',
        opacity: 0,
      },
    },
  },
  isBlue: {
    '& div': {
      boxShadow:
        'inset 0 0 0.3rem rgba(0, 153, 255, 1), inset 0 0 0.2rem rgba(0, 153, 255, 0.8)',
    },
  },
  isOrange: {
    '& div': {
      boxShadow:
        'inset 0 0 0.3rem rgba(255, 120, 0, 1), inset 0 0 0.2rem rgba(255, 120, 0, 0.8)',
    },
  },
};

const RemoveWarningIcon = () => (
  <>
    <WarningIcon fontSize="small" />
    <Box position="relative">
      <Typography
        position="absolute"
        left="-0.75rem"
        top="-0.5rem"
        fontSize="1.6rem"
        sx={{ transform: 'rotate(40deg)' }}
      >
        |
      </Typography>
    </Box>
  </>
);

export interface PlayerPieceProps {
  player: Player;
  color?: string;
  setPoints: (id: string, newPoints: number) => void;
  setWarnings: (id: string, newWarnings: number) => void;
  setAnimatedWarnings: Dispatch<SetStateAction<Animation[]>>;
  isLivesPointSystem: boolean;
  eliminated?: boolean;
}

export default function PlayerPiece({
  player,
  color,
  setPoints,
  setWarnings,
  setAnimatedWarnings,
  isLivesPointSystem,
  eliminated = false,
}: PlayerPieceProps) {
  const { id, points, warnings } = player;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [animatedMinusHearts, setMinusHearts] = useState<Animation[]>([]);
  const [animatedPlusHearts, setPlusHearts] = useState<Animation[]>([]);
  const [animatedMinusSkulls, setMinusSkulls] = useState<Animation[]>([]);
  const [animatedPlusSkulls, setPlusSkulls] = useState<Animation[]>([]);
  const [showWarningMenuItem, setShowWarningMenuItem] = useState(!eliminated);
  const [showRemoveWarningMenuItem, setShowRemoveWarningMenuItem] = useState(
    !eliminated && warnings > 0,
  );

  const isMenuOpen = Boolean(anchorEl);

  const playAnimation = (
    setter: Dispatch<SetStateAction<{ id: number }[]>>,
  ) => {
    const newIcon = { id: Date.now() };
    setter(prevIcons => [...prevIcons, newIcon]);

    setTimeout(() => {
      setter(prevIcons => prevIcons.filter(icon => icon.id !== newIcon.id));
    }, 2000);
  };

  const decreasePoints = () => {
    if (isLivesPointSystem) {
      if (eliminated) return;

      playAnimation(setMinusHearts);
      setPoints(id, points - 1);
    } else {
      playAnimation(setMinusSkulls);
      setPoints(id, points - 1);
    }
  };

  const increasePoints = () => {
    if (isLivesPointSystem) {
      playAnimation(setPlusHearts);
      setPoints(id, points + 1);
    } else {
      if (eliminated) return;

      playAnimation(setPlusSkulls);
      setPoints(id, points + 1);
    }
  };

  const decreaseWarnings = () => {
    if (warnings <= 0) return;
    setWarnings(id, Math.max(0, warnings - 1));
  };

  const increaseWarnings = () => {
    playAnimation(setAnimatedWarnings);
    setWarnings(id, warnings + 1);
  };

  const onClick = () => {
    if (isLivesPointSystem) {
      decreasePoints();
    } else {
      increasePoints();
    }
  };

  const onLongPress = (event: React.MouseEvent | React.TouchEvent) => {
    setAnchorEl(event.nativeEvent.target as HTMLElement);
  };

  const longPressEvent = useLongPress(onLongPress, onClick);

  useEffect(() => {
    setTimeout(() => {
      setShowWarningMenuItem(!eliminated);
      setShowRemoveWarningMenuItem(!eliminated && warnings > 0);
    }, 300);
  }, [eliminated, warnings]);

  return (
    <Stack alignItems="center">
      <Stack
        sx={[
          styles.player,
          eliminated
            ? {
                color: 'text.disabled',
                '& div': { borderColor: 'text.disabled' },
              }
            : { cursor: 'pointer' },
          color === 'blue' && styles.isBlue,
          color === 'orange' && styles.isOrange,
        ]}
        {...longPressEvent}
      >
        <Box sx={styles.head} />
        <Stack sx={styles.body}>
          <Typography fontWeight={500}>{points}</Typography>
        </Stack>
      </Stack>

      {animatedMinusHearts.length > 0 && (
        <Box position="relative">
          {animatedMinusHearts.map(heart => (
            <Box
              key={heart.id}
              sx={[styles.floatingHeart, { color: 'error.main' }]}
            >
              ❤️–
            </Box>
          ))}
        </Box>
      )}

      {animatedPlusHearts.length > 0 && (
        <Box position="relative">
          {animatedPlusHearts.map(heart => (
            <Box
              key={heart.id}
              sx={[styles.floatingHeart, { color: 'success.main' }]}
            >
              ❤️+
            </Box>
          ))}
        </Box>
      )}

      {animatedMinusSkulls.length > 0 && (
        <Box position="relative">
          {animatedMinusSkulls.map(skull => (
            <Box
              key={skull.id}
              sx={[styles.floatingHeart, { color: 'error.main' }]}
            >
              💀–
            </Box>
          ))}
        </Box>
      )}

      {animatedPlusSkulls.length > 0 && (
        <Box position="relative">
          {animatedPlusSkulls.map(skull => (
            <Box
              key={skull.id}
              sx={[styles.floatingHeart, { color: 'success.main' }]}
            >
              💀+
            </Box>
          ))}
        </Box>
      )}

      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={() => setAnchorEl(null)}
        onClick={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        disableAutoFocusItem
      >
        {isLivesPointSystem && (
          <MenuItem onClick={increasePoints}>
            <ListItemIcon>
              <HeartIcon fontSize="small" />
            </ListItemIcon>
            Öka liv
          </MenuItem>
        )}

        {!isLivesPointSystem && (
          <MenuItem onClick={decreasePoints}>
            <ListItemIcon>
              <RemoveIcon fontSize="small" />
            </ListItemIcon>
            Ta bort poäng
          </MenuItem>
        )}

        {showWarningMenuItem && (
          <MenuItem onClick={increaseWarnings}>
            <ListItemIcon>
              <WarningIcon fontSize="small" />
            </ListItemIcon>
            Ge varning
          </MenuItem>
        )}

        {showRemoveWarningMenuItem && (
          <MenuItem onClick={decreaseWarnings}>
            <ListItemIcon>
              <RemoveWarningIcon />
            </ListItemIcon>
            Ta bort varning
          </MenuItem>
        )}
      </Menu>
    </Stack>
  );
}
