import { Box, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { theme } from '../../theme';
import Judge from './JudgePiece';
import { ExtendedPlayer, isEliminated, Player } from './PlayerCircle';
import PlayerPiece from './PlayerPiece';

export type TeamColor = 'blue' | 'orange' | undefined;

export interface Team {
  id: string;
  index: number;
  name: string;
  players: readonly Player[];
}

export interface ExtendedTeam extends Team {
  color: TeamColor;
  points: number;
  warnings: number;
}

export interface TeamDetails {
  color: TeamColor;
  points: number;
}

export interface Animation {
  id: number;
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  circleWrapper: {
    position: 'relative',
    width: '100%',
    height: '70vw',
    borderRadius: '50%',
    marginX: 'auto',
  },
  cross: {
    position: 'relative',
    width: '6rem',
    height: '6rem',
    top: 14,

    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      left: '50%',
      width: '2px',
      height: '100%',
      backgroundColor: theme.palette.action.active,
    },

    '&::before': {
      transform: 'rotate(45deg)',
    },

    '&::after': {
      transform: 'rotate(-45deg)',
    },
  },
  circle: {
    position: 'absolute',
    width: 0,
    height: 0,
    borderRadius: '50%',
    top: '50%',
    left: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transformStyle: 'preserve-3d',
  },
  playerName: {
    zIndex: 2,
    mt: '3.2rem',
    pointerEvents: 'none',
  },
  growingWarning: {
    position: 'absolute',
    right: 1.35,
    top: 0,
    fontSize: '16px',
    whiteSpace: 'nowrap',
    animation: 'grow 2s forwards',

    '@keyframes grow': {
      '100%': {
        transform: 'scale(4) translateY(-2px)',
        opacity: 0,
      },
    },
  },
};

export interface TeamCirclePlayerProps {
  player: Player;
  color: TeamColor;
  angle: number;
  setTeamPoints: (teamId: string, newPoints: number) => void;
  setTeamWarnings: (teamId: string, newWarnings: number) => void;
  isLivesPointSystem: boolean;
  eliminated: boolean;
}

function TeamCirclePlayer({
  player,
  angle,
  setTeamPoints,
  setTeamWarnings,
  ...props
}: TeamCirclePlayerProps) {
  const [animatedWarnings, setAnimatedWarnings] = useState<Animation[]>([]);

  const { name, warnings } = player;
  const transform = `rotate(${angle}deg) translateX(min(30vw, 300px)) rotate(${-angle}deg)`;

  return (
    <>
      <Box sx={[styles.circle, { transform }]}>
        <PlayerPiece
          player={player}
          setPoints={setTeamPoints}
          setWarnings={setTeamWarnings}
          setAnimatedWarnings={setAnimatedWarnings}
          {...props}
        />
      </Box>

      <Stack sx={[styles.circle, styles.playerName, { transform }]}>
        <Typography
          variant="caption"
          textAlign="center"
          whiteSpace="nowrap"
          fontWeight={500}
          lineHeight={1}
        >
          {name}
        </Typography>

        <Box position="relative" component="span" height={0}>
          {warnings > 0 && (
            <>
              {'⚠️'.repeat(warnings)}
              {animatedWarnings.map(warning => (
                <Box key={warning.id} sx={styles.growingWarning}>
                  ⚠️
                </Box>
              ))}
            </>
          )}
        </Box>
      </Stack>
    </>
  );
}

export interface TeamCircleProps {
  players: readonly ExtendedPlayer[];
  setPlayers: (newPlayers: readonly Player[]) => void;
  isLivesPointSystem: boolean;
  maxPoints: number;
}

export default function TeamCircle({
  players,
  setPlayers,
  isLivesPointSystem,
  maxPoints,
}: TeamCircleProps) {
  const setPlayerPoints = (playerId: string, newPoints: number) => {
    const newPlayers = players.map(({ team, ...player }) =>
      player.id === playerId ? { ...player, points: newPoints } : player,
    );

    setPlayers(newPlayers);
  };

  const setTeamWarnings = (playerId: string, newWarnings: number) => {
    const newPlayers = players.map(({ team, ...player }) =>
      player.id === playerId ? { ...player, warnings: newWarnings } : player,
    );

    setPlayers(newPlayers);
  };

  return (
    <Stack sx={styles.container}>
      <Box sx={styles.circleWrapper}>
        {players.map((player, index) => {
          const color = player.team.color;
          const teamPoints = player.team.points;
          const angle = 90 + (180 * (2 * index + 1)) / players.length;

          return (
            <TeamCirclePlayer
              key={player.id}
              player={player}
              color={color}
              angle={angle}
              setTeamPoints={setPlayerPoints}
              setTeamWarnings={setTeamWarnings}
              isLivesPointSystem={isLivesPointSystem}
              eliminated={isEliminated(
                isLivesPointSystem,
                teamPoints,
                maxPoints,
              )}
            />
          );
        })}

        <Box sx={styles.circle}>
          <Box sx={styles.cross} />
        </Box>
      </Box>

      <Judge />
    </Stack>
  );
}
