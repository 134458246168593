import StarIcon from '@mui/icons-material/StarBorderOutlined';
import {
  Box,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material';
import Container from '@mui/material/Container';
import { useEffect, useState } from 'react';
import Drawer from '../../components/Drawer';
import { Heading } from '../../components/Heading';
import { Spinner } from '../../components/Spinner';
import { useAuth } from '../../contexts/authContext';
import { getUsers, User } from '../../firebase/api';
import MedalIcon from '../../icons/MedalIcon';
import { BACKGROUND_COLOR } from '../../theme';
import OtherProfilePage from '../OtherProfilePage/OtherProfilePage';
import { getRatingType } from '../ProfilePage/ProfilePage';

const styles = {
  tabs: {
    width: '100%',

    '& > div': {
      borderBottom: 1,
      borderColor: 'divider',
    },

    '& .MuiTab-root': {
      minWidth: 0,
      flexGrow: 1,
      flexBasis: 0,
      maxWidth: 'none',
    },
  },
  tableContainer: {
    maxHeight: 'calc(100vh - 300px)',
  },
  tableRow: {
    height: 58,

    '&:last-child td': {
      borderBottom: 0,
    },
  },
  selfTableRow: {
    position: 'sticky',
    bottom: 4,
    backgroundColor: 'white',
    zIndex: 1,
    borderRadius: 1,
    boxShadow:
      '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',

    '& td': {
      borderBottom: 0,
    },
  },
  aboveSelfTableRow: {
    '& td': {
      borderBottom: 0,
    },
  },
  borderLeftRadius: {
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
  },
  borderRightRadius: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  },
};

export const LeaderboardPage = () => {
  const { user: self } = useAuth();
  const theme = useTheme();
  const [users, setUsers] = useState<User[] | undefined>(undefined);
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);
  const [isOtherProfileDrawerOpen, setIsOtherProfileDrawerOpen] =
    useState(false);
  const [currentTab, setCurrentTab] = useState(0);

  const ratingType = getRatingType(currentTab);
  const ratingField = ('rating' + (ratingType ?? '')) as
    | 'rating'
    | 'rating7'
    | 'rating9'
    | 'rating11'
    | 'rating13';
  const sortedUsers = users?.toSorted(
    (a, b) => b[ratingField] - a[ratingField],
  );

  useEffect(() => {
    const fetchUsers = async () => {
      const users = await getUsers();
      setUsers(users);
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    if (selectedUser === undefined) return;
    setIsOtherProfileDrawerOpen(true);
  }, [selectedUser]);

  const loading = sortedUsers !== undefined;
  const userIndex = sortedUsers?.findIndex(user => user.id === self?.id);

  return (
    <Container component="main" maxWidth="sm">
      <Stack gap={4} py={2}>
        <Heading>Topplista</Heading>

        <Tabs
          value={currentTab}
          onChange={(_, newValue) => setCurrentTab(newValue)}
          aria-label="tabs"
          sx={styles.tabs}
        >
          <Tab value={0} icon={<StarIcon />} aria-label="main rating" />
          <Tab value={1} label="7" />
          <Tab value={2} label="9" />
          <Tab value={3} label="11" />
          <Tab value={4} label="13" />
        </Tabs>

        {loading ? (
          <>
            <TableContainer sx={styles.tableContainer}>
              <Table
                size="small"
                aria-label="leaderboard"
                stickyHeader
                sx={{ px: 1 }}
              >
                <TableHead>
                  <TableRow
                    sx={{ '& > th': { backgroundColor: BACKGROUND_COLOR } }}
                  >
                    <TableCell align="left"></TableCell>
                    <TableCell align="left">Capsare</TableCell>
                    <TableCell align="right">Rating</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {sortedUsers.map((user, index) => {
                    const isSelf = index === userIndex;
                    const isAboveSelf = index + 1 === userIndex;

                    return (
                      <TableRow
                        key={user.nameLabel}
                        onClick={() => setSelectedUser(user)}
                        sx={[
                          styles.tableRow,
                          isSelf && styles.selfTableRow,
                          isSelf && { top: 37 + (userIndex !== 0 ? 4 : 0) },
                          isAboveSelf && styles.aboveSelfTableRow,
                        ]}
                      >
                        <TableCell sx={[isSelf && styles.borderLeftRadius]}>
                          #{index + 1}
                        </TableCell>
                        <TableCell>
                          <Box display="flex" alignItems="center" gap={1}>
                            {user.numberOfGlassesMedal !== undefined && (
                              <MedalIcon>{user.numberOfGlassesMedal}</MedalIcon>
                            )}
                            <div>
                              <Typography>{user.nameLabel}</Typography>
                              <Typography
                                variant="caption"
                                color="text.secondary"
                              >
                                {user.associationLabel}
                              </Typography>
                            </div>
                          </Box>
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={[isSelf && styles.borderRightRadius]}
                        >
                          <Typography>
                            {Math.round(user[ratingField])}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>

            <Drawer
              open={isOtherProfileDrawerOpen}
              onClose={() => {
                setIsOtherProfileDrawerOpen(false);

                setTimeout(() => {
                  setSelectedUser(undefined);
                }, theme.transitions.duration.leavingScreen);
              }}
            >
              {selectedUser !== undefined && (
                <OtherProfilePage user={selectedUser} />
              )}
            </Drawer>
          </>
        ) : (
          <Spinner />
        )}
      </Stack>
    </Container>
  );
};
