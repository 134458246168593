import RemoveIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Container,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Heading } from '../../components/Heading';
import Link from '../../components/Link';
import PlayerNameAutocomplete from '../../components/PlayerNameAutocomplete';
import PointSystemToggle, {
  PointSystem,
} from '../../components/PointSystemToggle';
import { JudgeCircleState } from '../JudgeCirclePage/JudgeCirclePage';

export default function JudgeCircleCreatePage() {
  const navigate = useNavigate();
  const [pointSystem, setPointSystem] = useState<PointSystem>('lives');
  const [maxPoints, setMaxPoints] = useState(0);
  const [names, setNames] = useState(['']);

  const isLivesPointSystem = pointSystem === 'lives';

  function resetForm() {
    setPointSystem('lives');
    setMaxPoints(0);
    setNames(['']);
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const initialState: JudgeCircleState = {
      pointSystem,
      maxPoints,
      players: names.map((name, index) => ({
        id: crypto.randomUUID(),
        index,
        name,
        points: isLivesPointSystem ? maxPoints : 0,
        warnings: 0,
        ranking: 1,
      })),
    };

    localStorage.setItem('tools.judgeCircle', JSON.stringify(initialState));
    navigate('judge-circle');
    resetForm();
  }

  return (
    <Container component="main" maxWidth="sm">
      <form
        onSubmit={handleSubmit}
        onKeyDown={event => {
          if (event.key === 'Enter') {
            event.preventDefault();
          }
        }}
      >
        <Stack gap={4} p={2}>
          <Heading>Döm cirkel</Heading>

          <PointSystemToggle value={pointSystem} onChange={setPointSystem} />

          <TextField
            label={
              isLivesPointSystem ? 'Antal liv' : 'Antal poäng för att åka ut'
            }
            type="number"
            value={maxPoints || ''}
            onChange={event => setMaxPoints(Number(event.target.value))}
            required
            inputProps={{ min: 1 }}
          />

          <div>
            <Stack gap={4}>
              {names.map((playerName, index) => (
                <PlayerNameAutocomplete
                  key={index}
                  value={playerName}
                  onKeyDown={event => {
                    if (event.key === 'Enter') {
                      setNames([...names, '']);
                    }
                  }}
                  onChange={newValue => {
                    const newPlayerNames = [...names];
                    newPlayerNames[index] = newValue;
                    setNames(newPlayerNames);
                  }}
                  sx={[index === 1 && { mt: -2.75 }]}
                  textFieldProps={{
                    label: `Capsare ${index + 1}`,
                    helperText:
                      index === 0
                        ? 'Börja med capsaren närmast till vänster om dig'
                        : undefined,
                    autoFocus: index > 0,
                    required: true,
                    InputProps: {
                      endAdornment:
                        names.length > 1 ? (
                          <InputAdornment position="end" sx={{ mr: 0.5 }}>
                            <RemoveIcon
                              fontSize="small"
                              aria-label="Ta bort capsare"
                              onClick={() => {
                                const newNames = [...names];
                                newNames.splice(index, 1);
                                setNames(newNames);
                              }}
                            />
                          </InputAdornment>
                        ) : undefined,
                    },
                  }}
                />
              ))}
            </Stack>

            <Box display="flex" justifyContent="flex-end" mt={1}>
              <Typography variant="body2">
                <Link
                  variant="body2"
                  onClick={() => setNames([...names, ''])}
                  sx={{ textDecoration: 'none' }}
                >
                  Lägg till +
                </Link>
              </Typography>
            </Box>
          </div>

          <Button type="submit" variant="contained" sx={{ mb: 2 }}>
            Döm cirkel
          </Button>
        </Stack>
      </form>
    </Container>
  );
}
